import FetchUtil from "../utils/FetchUtil";
import store from "store";
import {Modules} from "../router/router";
import {showIcon} from "../components/MyUploadIcon";

export default class MenuRouterData {

    static load = async () => {

        const res = await FetchUtil.awaitFetch("/adminApi/function/tree", {id: 3})
        console.log(res)
        if (res.code === 200) {
            // if (JSON.stringify(login_power) !== JSON.stringify(res.data)) {
            store.set('login_power', res.data);
            const menuRouter = LoadPowerMenuRouter(res.data, "");
            console.log(menuRouter);
            return menuRouter;
            // }else {
            //     return appState?.menuRouter;
            // }
        } else {
            window.location.replace('/#/login');
            return [];
        }
    }
}

export const LoadPowerMenuRouter = (funList: any[], parentPath:string): any => {

    const routerMenu: any[] = funList.map((r: any) => {
        let routPath: string;
        let linkPath = "";
        if (r.pageUrl.includes("#")) {
            let thisPath = r.pageUrl.split("#");
            let routerPath = thisPath[0].split("/");
            let routerParam = thisPath[1].split(":");
            let routerIndex = 0;
            routPath=thisPath[0];

            for (let i = 0; i < routerPath.length; i++) {
                let strPath = routerPath[i];
                if (strPath.length > 1 && strPath.substring(0, 1) === ":") {
                    linkPath += "/" + routerParam[routerIndex];
                    routerIndex++;
                } else {
                    linkPath += "/" + strPath;
                }
            }
            linkPath = linkPath.substring(1);
        } else {
            routPath = r.pageUrl;
            linkPath = r.pageUrl;
        }
        linkPath = parentPath + "/" + linkPath;

        return {
            key: r.functionId,
            name: r.functionName,
            path: routPath,
            icon: showIcon(r.icon),
            iconName: r.icon,
            linkPath: linkPath,
            pageUrl: r.pageUrl,
            // element: <Suspense fallback={<Spin/>}><Module/></Suspense>,
            interface: r.functionInterface,
            Component: Modules[r.functionInterface],
            children: LoadPowerMenuRouter(r.children, linkPath),
        }
    })
    // routerMenu.push({path: "*", Component: Page404})
    // store.set("login_routerMenu", routerMenu)
    // console.log("power to routerMenu", routerMenu)
    return routerMenu
}
